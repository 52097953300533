import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Box, Button, Tooltip } from "@mui/material";
import { Delete } from "@mui/icons-material";

const DeleteDialog = ({ open, setOpen, title, text, handleSubmit }) => {
    return (
        <Tooltip title={"Delete"}>
            <Button onClick={() => setOpen(true)} >
                <Delete />
            </Button>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle id="status-dialog-title">
                    <Box sx={{
                        borderRadius: "25px",
                        width: 130,
                        margin: "auto"
                    }}>
                        <Typography component="div" sx={{ fontWeight: "bold" }}>
                            {"Confirm Delete?"}
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="status-dialog-description">
                        <Typography component="div" sx={{ fontWeight: "bold" }}>
                            {`${title}`}
                        </Typography>
                        <Typography component="div" sx={{ whiteSpace: "pre-line" }}>
                            {`${text}`}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit}>Yes</Button>
                    <Button onClick={() => setOpen(false)}>No</Button>
                </DialogActions>
            </Dialog>
        </Tooltip>

    )
};

export default DeleteDialog;