import React from "react";
import { Box, Grid } from "@mui/material";
import NavBar from "../../components/NavBar";

const Profile = ({ user, signOut }) => {
    return (
        <>
            <NavBar user={user} signOut={signOut} />
            <Box sx={{ padding: 2 }}>
                <Grid container sx={{ width: "20rem", margin: "auto" }}>
                    <Grid item xs={2} sx={{ fontWeight: "bold" }}>
                        {`Email: `}
                    </Grid>
                    <Grid item xs={8}>
                        {`${user?.username.substring(14)}`}
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default Profile;