import { get, post, put, del } from 'aws-amplify/api';

export const fetchGetResults = async (documentId) => {
    try {
        const restOperation = get({
            apiName: 'PinkyResult',
            path: `/pinky/result/${documentId}`
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response.StatusCode === 200) {
            return response.Data;
        }
    } catch (e) {
        return [];
    }
    return [];
};

export const fetchCreateResultList = async (list) => {
    try {
        const restOperation = post({
            apiName: 'PinkyResult',
            path: '/pinky/result',
            options: {
                body: {
                    List: list
                }
            }
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response.StatusCode === 200) {
            return true;
        }
    } catch (e) {
        return false;
    }
    return false;
};

export const fetchUpdateResultList = async (list) => {
    try {
        const restOperation = put({
            apiName: 'PinkyResult',
            path: '/pinky/result',
            options: {
                body: {
                    List: list
                }
            }
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response.StatusCode === 200) {
            return true;
        }
    } catch (e) {
        return false;
    }
    return false;
};

export const fetchDeleteResultList = async (list) => {
    try {
        const restOperation = del({
            apiName: 'PinkyResult',
            path: `/pinky/result/${list.join(",")}`
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response.StatusCode === 200) {
            return true;
        }
    } catch (e) {
        return false;
    }
    return false;
};