import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, Tabs, Typography, Box, Grid, LinearProgress } from "@mui/material";
import ScrollToTop from "react-scroll-to-top";
import { fetchGetDocuments, fetchCreateDocumentList, fetchDeleteDocumentList, fetchUpdateDocumentList } from "../../services/Document";
import { fetchGetSections, fetchCreateSectionList, fetchDeleteSectionList, fetchUpdateSectionList } from "../../services/Section";
import { fetchGetRequirements, fetchCreateRequirementList, fetchDeleteRequirementList, fetchUpdateRequirementList } from "../../services/Requirement";
import { fetchGetResults } from "../../services/Result";
import { fetchGenerateResult, fetchGenerateDocument } from "../../services/Generate";
import { fetchGetPromptSources } from "../../services/PromptSource";
import Request from "./Request";
import Response from "./Response";
import useQuickAlert from "../../components/QuickAlert";
import ToolBar from "../../components/ToolBar";
import NavBar from "../../components/NavBar";
import GenerateDocument from "../../components/GenerateDocument";

const Proposal = ({ user, signOut }) => {
    const navigate = useNavigate();
    const addAlert = useQuickAlert();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [value, setValue] = useState(0);
    const [documents, setDocuments] = useState([]);
    const [sections, setSections] = useState([]);
    const [requirements, setRequirements] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [results, setResults] = useState([]);
    const createList = useRef([]);
    const updateList = useRef([]);
    const deleteList = useRef([]);

    const setCreateList = (newList) => {
        createList.current = newList;
    };

    const setUpdateList = (newList) => {
        updateList.current = newList;
    };

    const setDeleteList = (newList) => {
        deleteList.current = newList;
    };

    const addCreateList = (object) => {
        const exists = createList.current.find(item =>
            item.Type === object.Type && item.Body.Id === object.Body.Id
        );
        if (exists) {
            createList.current = createList.current.filter(item =>
                !(item.Type === object.Type && item.Body.Id === object.Body.Id)
            );
            createList.current = [...createList.current, object];
        }
        else {
            createList.current = [...createList.current, object];
        }
    };

    const addUpdateList = (object) => {
        const exists = updateList.current.find(item =>
            item.Type === object.Type && item.Field === object.Field && item.Id === object.Id
        );
        if (exists) {
            updateList.current = updateList.current.filter(item =>
                !(item.Type === object.Type && item.Field === object.Field && item.Id === object.Id)
            );
            updateList.current = [...updateList.current, object];
        }
        else {
            updateList.current = [...updateList.current, object];
        }
        for (const obj of updateList.current) {
            const createExists = createList.current.find(item => item.Body.Id === obj.Id && item.Type === obj.Type);
            if (createExists) {
                createList.current = createList.current.map(item => {
                    if (item.Body.Id === obj.Id) {
                        return {
                            ...item,
                            Body: {
                                ...item.Body,
                                [obj.Field]: obj.Value
                            }
                        }
                    }
                    else {
                        return {
                            ...item
                        }
                    }
                })
            }
        }
        for (const obj of createList.current) {
            const updateExists = updateList.current.find(item => item.Id === obj.Body.Id && item.Type === obj.Type);
            if (updateExists) {
                updateList.current = updateList.current.filter(item => !(item.Id === obj.Body.Id && item.Type === obj.Type));
            }
        }
    };

    const addDeleteList = (object) => {
        const exists = deleteList.current.find(item =>
            item.Type === object.Type && item.Id === object.Id
        );
        if (exists) {
            deleteList.current = deleteList.current.filter(item =>
                !(item.Type === object.Type && item.Id === object.Id)
            );
            deleteList.current = [...deleteList.current, object];
        }
        else {
            deleteList.current = [...deleteList.current, object];
        }
        for (const obj of deleteList.current) {
            const createExists = createList.current.find(item => item.Body.Id === obj.Id && item.Type === obj.Type);
            if (createExists) {
                createList.current = createList.current.filter(item => !(item.Body.Id === obj.Id && item.Type === obj.Type));
            }
        }
    };

    useEffect(() => {
        setValue(Number(params.get("Document")));
    }, []);

    useEffect(() => {
        const AllDocuments = async () => {
            const allDocuments = await fetchGetDocuments(user.userId);
            if (allDocuments) {
                if (allDocuments.length === 0) {
                    handleCreate("Document");
                }
                else {
                    setDocuments(allDocuments);
                }
            }
        }
        setIsLoading(true);
        AllDocuments();
    }, [user, value]);

    useEffect(() => {
        const AllSections = async (documentId) => {
            const allSections = await fetchGetSections(documentId);
            if (allSections) {
                setSections(allSections);
            }
        }
        if (documents[value]?.Id) {
            AllSections(documents[value].Id);
        }
    }, [documents]);

    useEffect(() => {
        const AllRequirements = async (documentId) => {
            const allRequirements = await fetchGetRequirements(documentId);
            if (allRequirements) {
                setRequirements(allRequirements);
            }
        }
        if (documents[value]?.Id) {
            AllRequirements(documents[value].Id);
        }
    }, [sections]);

    useEffect(() => {
        const AllResults = async (documentId) => {
            const allResults = await fetchGetResults(documentId);
            if (allResults) {
                const allSources = await fetchGetPromptSources(documentId);
                if (allSources) {
                    const finalResults = allResults.map(item => {
                        return {
                            ...item,
                            Sources: allSources.filter(source => source.ResultId === item.Id)
                        }
                    })
                    setResults(finalResults);
                    setIsLoading(false);
                }
            }
        }
        if (documents[value]?.Id) {
            AllResults(documents[value].Id);
        }
    }, [requirements]);

    const handleCreate = (type, documentId, sectionId) => {
        let body = {};
        if (type === "Document") {
            body = {
                Id: crypto.randomUUID(),
                UserId: user.userId,
                Name: "New Document",
                Customer: "",
                Summary: "",
                SortOrder: documents.length + 1
            };
            handleCreateDocumentList([body]);
        }
        if (type === "Section") {
            body = {
                Id: crypto.randomUUID(),
                DocumentId: documentId,
                Name: "",
                Customer: "",
                Text: "",
                SortOrder: sections.length + createList.current.filter(item => item.Type === "Section").length + 1
            };
            addCreateList({ Type: type, Body: body });
        }
        else if (type === "Requirement") {
            body = {
                Id: crypto.randomUUID(),
                DocumentId: documentId,
                SectionId: sectionId,
                Text: "",
                Prompt: "",
                AdditionalInstructions: "",
                UseGeneratedPrompt: true,
                SortOrder: requirements.length + createList.current.filter(item => item.Type === "Requirement").length + 1
            };
            addCreateList({ Type: type, Body: body });
        }
        return body;
    };

    const createAPI = () => {
        let createSectionList = [];
        let createRequirementList = [];
        for (const item of createList.current) {
            if (item.Type === "Section") {
                createSectionList = [...createSectionList, item.Body];
            }
            else if (item.Type === "Requirement") {
                createRequirementList = [...createRequirementList, item.Body];
            }
        }

        if (createSectionList.length > 0) {
            handleAddSectionList(createSectionList);
        }
        if (createRequirementList.length > 0) {
            handleAddRequirementList(createRequirementList);
        }
    };

    const updateAPI = () => {
        let newDocuments = documents;
        let newSections = sections;
        let newRequirements = requirements;

        let updateDocumentListIds = [];
        let updateSectionListIds = [];
        let updateRequirementListIds = [];

        for (const item of updateList.current) {
            if (item.Type === "Document") {
                updateDocumentListIds = [...new Set([...updateDocumentListIds, item.Id])];
                const tempDocuments = newDocuments.map(document => {
                    if (document.Id === item.Id) {
                        return {
                            ...document,
                            [item.Field]: item.Value
                        };
                    }
                    else {
                        return {
                            ...document
                        }
                    }
                });
                newDocuments = tempDocuments;
            }
            else if (item.Type === "Section") {
                updateSectionListIds = [...new Set([...updateSectionListIds, item.Id])];
                const tempSections = newSections.map(section => {
                    if (section.Id === item.Id) {
                        return {
                            ...section,
                            [item.Field]: item.Value
                        };
                    }
                    else {
                        return {
                            ...section
                        }
                    }
                });
                newSections = tempSections;
            }
            else if (item.Type === "Requirement") {
                updateRequirementListIds = [...new Set([...updateRequirementListIds, item.Id])];
                const tempRequirements = newRequirements.map(requirement => {
                    if (requirement.Id === item.Id) {
                        return {
                            ...requirement,
                            [item.Field]: item.Value
                        };
                    }
                    else {
                        return {
                            ...requirement
                        }
                    }
                });
                newRequirements = tempRequirements;
            }
        }

        let finalUpdateDocumentList = [];
        for (const id of updateDocumentListIds) {
            const doc = newDocuments.find(item => item.Id === id);
            if (doc) {
                finalUpdateDocumentList = [...finalUpdateDocumentList, doc];
            }
        }
        if (finalUpdateDocumentList.length > 0) {
            handleUpdateDocumentList(finalUpdateDocumentList);
        }

        let finalUpdateSectionList = [];
        for (const id of updateSectionListIds) {
            const sec = newSections.find(item => item.Id === id);
            if (sec) {
                finalUpdateSectionList = [...finalUpdateSectionList, sec];
            }
        }
        if (finalUpdateSectionList.length > 0) {
            handleUpdateSectionList(finalUpdateSectionList);
        }

        let finalUpdateRequirementList = [];
        for (const id of updateRequirementListIds) {
            const req = newRequirements.find(item => item.Id === id);
            if (req) {
                finalUpdateRequirementList = [...finalUpdateRequirementList, req];
            }
        }
        if (finalUpdateRequirementList.length > 0) {
            handleUpdateRequirementList(finalUpdateRequirementList);
        }
    };

    const deleteAPI = () => {
        let deleteDocumentListIds = [];
        let deleteSectionListIds = [];
        let deleteRequirementListIds = [];

        for (const item of deleteList.current) {
            if (item.Type === "Document") {
                deleteDocumentListIds = [...new Set([...deleteDocumentListIds, item.Id])];
            }
            else if (item.Type === "Section") {
                deleteSectionListIds = [...new Set([...deleteSectionListIds, item.Id])];
            }
            else if (item.Type === "Requirement") {
                deleteRequirementListIds = [...new Set([...deleteRequirementListIds, item.Id])];
            }
        }

        if (deleteDocumentListIds.length > 0) {
            handleDeleteDocumentList(deleteDocumentListIds);
            for (const docId of deleteDocumentListIds) {
                const sectionIds = sections.filter(sec => sec.DocumentId === docId).map(sec2 => sec2.Id);
                deleteSectionListIds = [...deleteSectionListIds, ...sectionIds];;
                for (const secId of deleteSectionListIds) {
                    const requirementsIds = requirements.filter(req => req.SectionId === secId).map(req2 => req2.Id);
                    deleteRequirementListIds = [...new Set([...deleteRequirementListIds, ...requirementsIds])];
                }
            }
        }
        if (deleteSectionListIds.length > 0) {
            handleDeleteSectionList(deleteSectionListIds);
            for (const secId of deleteSectionListIds) {
                const requirementsIds = requirements.filter(req => req.SectionId === secId).map(req2 => req2.Id);
                deleteRequirementListIds = [...new Set([...deleteRequirementListIds, ...requirementsIds])];
            }
        }
        if (deleteRequirementListIds.length > 0) {
            handleDeleteRequirementList(deleteRequirementListIds);
        }
    };

    const handleSaveChange = () => {
        createAPI();
        updateAPI();
        deleteAPI();
        resetForm();
    };

    const handleCancelChange = () => {
        resetForm();
    };

    const resetForm = () => {
        setIsEdit(false);
        setCreateList([]);
        setUpdateList([]);
        setDeleteList([]);
    };

    const handleDocumentChange = (event, newValue) => {
        setValue(newValue);
        params.set("Document", newValue);
        navigate(`/Proposal?${params.toString()}`);
    };

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography component="span">{children}</Typography>
                    </Box>
                )}
            </div>
        );
    };

    const a11yProps = (index) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    };

    const handleCreateDocumentList = async (list) => {
        const res = await fetchCreateDocumentList(list);
        if (res) {
            addAlert({
                message: "Successfully created a New Document",
                severity: "success",
            });
            const refreshDocuments = await fetchGetDocuments(user.userId);
            if (refreshDocuments) {
                setDocuments(refreshDocuments);
                setValue(refreshDocuments.length - 1);
                setIsEdit(true);
            }
        }
        else {
            addAlert({
                message: "Failed to create a New Document",
                severity: "error",
            });
        }
    };

    const handleDeleteDocumentList = async (list) => {
        const res = await fetchDeleteDocumentList(list);
        if (res) {
            addAlert({
                message: "Successfully deleted the Document",
                severity: "success",
            });
            const refreshDocuments = await fetchGetDocuments(user.userId);
            if (refreshDocuments) {
                if (refreshDocuments.length === 0) {
                    handleCreate("Document")
                }
                else {
                    setDocuments(refreshDocuments);
                }
            }
            setValue(0);
            params.set("Document", 0);
        }
        else {
            addAlert({
                message: "Failed to delete the Document",
                severity: "error",
            });
        }
    };

    const handleUpdateDocumentList = async (list) => {
        const res = await fetchUpdateDocumentList(list);
        if (res) {
            addAlert({
                message: "Successfully updated the Document",
                severity: "success",
            });
            const refreshDocuments = await fetchGetDocuments(user.userId);
            if (refreshDocuments) {
                setDocuments(refreshDocuments);
            }
        }
        else {
            addAlert({
                message: "Failed to delete the Document",
                severity: "error",
            });
        }
    };

    const handleAddSectionList = async (list) => {
        const res = await fetchCreateSectionList(list);
        if (res) {
            addAlert({
                message: "Successfully created a New Section",
                severity: "success",
            });
            const refreshSections = await fetchGetSections(documents[value]?.Id);
            if (refreshSections) {
                setSections(refreshSections);
            }
        }
        else {
            addAlert({
                message: "Failed to create a New Section",
                severity: "error",
            });
        }
    };

    const handleDeleteSectionList = async (list) => {
        const res = await fetchDeleteSectionList(list);
        if (res) {
            addAlert({
                message: "Successfully deleted a Section",
                severity: "success",
            });
            const refreshSections = await fetchGetSections(documents[value]?.Id);
            if (refreshSections) {
                setSections(refreshSections);
            }
        }
        else {
            addAlert({
                message: "Failed to deleted a Section",
                severity: "error",
            });
        }
    };

    const handleUpdateSectionList = async (list) => {
        const res = await fetchUpdateSectionList(list);
        if (res) {
            addAlert({
                message: "Successfully updated a Section",
                severity: "success",
            });
            const refreshSections = await fetchGetSections(documents[value]?.Id);
            if (refreshSections) {
                setSections(refreshSections);
            }
        }
        else {
            addAlert({
                message: "Failed to update a Section",
                severity: "error",
            });
        }
    };

    const handleAddRequirementList = async (list) => {
        const res = await fetchCreateRequirementList(list);
        if (res) {
            addAlert({
                message: "Successfully created a New Requirement",
                severity: "success",
            });
            const refreshRequirements = await fetchGetRequirements(documents[value]?.Id);
            if (refreshRequirements) {
                setRequirements(refreshRequirements);
            }
        }
        else {
            addAlert({
                message: "Failed to create a New Requirement",
                severity: "error",
            });
        }
    };

    const handleDeleteRequirementList = async (list) => {
        const res = await fetchDeleteRequirementList(list);
        if (res) {
            addAlert({
                message: "Successfully deleted a Requirement",
                severity: "success",
            });
            const refreshRequirements = await fetchGetRequirements(documents[value]?.Id);
            if (refreshRequirements) {
                setRequirements(refreshRequirements);
            }

        }
        else {
            addAlert({
                message: "Failed to deleted a Requirement",
                severity: "error",
            });
        }
    };

    const handleUpdateRequirementList = async (list) => {
        const res = await fetchUpdateRequirementList(list);
        if (res) {
            addAlert({
                message: "Successfully updated a Requirement",
                severity: "success",
            });
            const refreshRequirements = await fetchGetRequirements(documents[value]?.Id);
            if (refreshRequirements) {
                setRequirements(refreshRequirements);
            }
        }
        else {
            addAlert({
                message: "Failed to update a Requirement",
                severity: "error",
            });
        }
    };

    const handleSubmit = async (documentId) => {
        setIsLoading(true);
        const res = await fetchGenerateResult(documentId);
        if (res) {
            if (res.statusCode === 200) {
                addAlert({
                    message: "Successfully Submitted",
                    severity: "success",
                });
                const allResults = await fetchGetResults(documentId);
                if (allResults) {
                    const allSources = await fetchGetPromptSources(documentId);
                    if (allSources) {
                        const finalResults = allResults.map(item => {
                            return {
                                ...item,
                                Sources: allSources.filter(source => source.ResultId === item.Id)
                            }
                        })
                        setResults(finalResults);
                    }
                }
            }
            else {
                addAlert({
                    message: res.statusMessage,
                    severity: "error",
                });
            }
        }
        else {
            addAlert({
                message: "Failed to Submit",
                severity: "error",
            });
        }
        setIsLoading(false);
    };

    const handleDownload = async (documentId) => {
        setIsLoading(true);
        const res = await fetchGenerateDocument(documentId);
        if (res.StatusCode === 200) {
            GenerateDocument(res.File, res.Filename, res.ContentType);
        }
        else {
            addAlert({
                message: res.StatusMessage,
                severity: "error",
            });
        }
        setIsLoading(false);
    };

    const handleRefresh = async (documentId) => {
        setIsLoading(true);
        const allResults = await fetchGetResults(documentId);
        if (allResults) {
            const allSources = await fetchGetPromptSources(documentId);
            if (allSources) {
                const finalResults = allResults.map(item => {
                    return {
                        ...item,
                        Sources: allSources.filter(source => source.ResultId === item.Id)
                    }
                })
                setResults(finalResults);
            }
        }
        setIsLoading(false);
    };

    const handleRefreshNoLoading = async (documentId) => {
        const allResults = await fetchGetResults(documentId);
        if (allResults) {
            const allSources = await fetchGetPromptSources(documentId);
            if (allSources) {
                const finalResults = allResults.map(item => {
                    return {
                        ...item,
                        Sources: allSources.filter(source => source.ResultId === item.Id)
                    }
                })
                setResults(finalResults);
            }
        }
    };

    const CustomToolBar = () => {
        return (
            <ToolBar
                handleCreateDocument={() => handleCreate("Document")}
                handleSubmit={() => handleSubmit(documents[value].Id)}
                handleDownload={() => handleDownload(documents[value].Id)}
                handleRefresh={() => handleRefresh(documents[value].Id)}
                handleSaveChange={handleSaveChange}
                handleCancelChange={handleCancelChange}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
            />
        )
    };

    const tabWidth = 1450;

    return (
        <>
            <NavBar user={user} signOut={signOut} CustomToolBar={CustomToolBar} />
            {isLoading ?
                <Box sx={{ left: 0, right: 0, top: 45, position: "absolute" }}>
                    <LinearProgress />
                </Box> :
                <Box sx={{ padding: 2 }}>
                    <Box sx={{ maxWidth: { xs: 320 + tabWidth, sm: 480 + tabWidth }, bgcolor: 'background.paper' }}>
                        <Tabs
                            value={value}
                            onChange={handleDocumentChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            {documents.map((document, index) =>
                                <Tab key={`documentSideBar${document.Id}-${index}`} label={document.Name.length > 12 ? `${document.Name.slice(0, 12)}...` : document.Name} {...a11yProps(index)}
                                    sx={{ textTransform: "none", fontSize: "1rem", width: 150, }}
                                />
                            )}
                        </Tabs>
                    </Box>
                    {documents.map((document, index) =>
                        <TabPanel key={`documentPanel-${document.Id}-${index}`} value={value} index={index}>
                            <Grid container sx={{ mt: "-1.2rem" }}>
                                <Grid item xs={6} sx={{ border: isEdit ? "1px solid lightgray" : "", borderRadius: "25px" }}>
                                    <Request
                                        document={document}
                                        sections={sections}
                                        requirements={requirements}
                                        isEdit={isEdit}
                                        handleCreate={handleCreate}
                                        addUpdateList={addUpdateList}
                                        addDeleteList={addDeleteList}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Response results={results} handleRefreshNoLoading={() => handleRefreshNoLoading(documents[value].Id)} />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    )}
                    <ScrollToTop smooth color="white" style={{ backgroundColor: "#906B7F", marginBottom: "3rem" }} />
                </Box>
            }
        </>
    )
}

export default Proposal;