import React, { useState } from "react";
import { Grid, TextField, Typography, Select, MenuItem } from "@mui/material";
import DeleteDialog from "./DeleteDialog";

const InlineEditText = ({ type, field, id, text, isEdit, handleUpdate, handleDelete, selectOptions, required }) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [newText, setNewText] = useState(text);
    const [lineThrough, setLineThrough] = useState(false);

    const handleOnChange = (e) => {
        setNewText(e.target.value);
    };

    const handleOnBlur = () => {
        handleUpdate(type, field, id, newText);
    };

    const handleOnDelete = () => {
        handleDelete(type, id);
        setOpenDeleteDialog(false);
        setLineThrough(true);
    };

    return (
        <>
            {isEdit ?
                <Grid container >
                    <Grid item xs={10}>
                        {selectOptions ?
                            <Select
                                fullWidth
                                value={newText}
                                onChange={handleOnChange}
                                onBlur={handleOnBlur}
                            >
                                {selectOptions.map((option, index) =>
                                    <MenuItem key={`${option}-${index}`} value={option}>{option}</MenuItem>
                                )}
                            </Select>
                            :
                            <TextField
                                fullWidth
                                multiline
                                value={newText}
                                onChange={handleOnChange}
                                onBlur={handleOnBlur}
                                sx={{ textDecoration: lineThrough ? "line-through" : "" }}
                                required={required}
                                placeholder={required ? "REQUIRED *" : null}
                                error={required && newText === "" ? true : false}
                            />
                        }
                    </Grid>
                    <Grid item xs={2} sx={{ mt: 1 }}>
                        {handleDelete ?
                            <DeleteDialog
                                open={openDeleteDialog}
                                setOpen={setOpenDeleteDialog}
                                title={`${type} ${field}:`}
                                text={`${newText}`}
                                handleSubmit={handleOnDelete}
                            />
                            : null
                        }
                    </Grid>
                </Grid>
                :
                <Grid container >
                    <Grid item xs={12}>
                        <Typography
                            component="span"
                            sx={{ float: "left", display: "block", mt: 1 }}
                        >
                            {text}
                        </Typography>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default InlineEditText;