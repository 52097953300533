import './App.css';
import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
// Imports the Amplify library from 'aws-amplify' package. This is used to configure your app to interact with AWS services.
import { Amplify } from 'aws-amplify';

// Imports the Authenticator and withAuthenticator components from '@aws-amplify/ui-react'.
// Authenticator is a React component that provides a ready-to-use sign-in and sign-up UI.
// withAuthenticator is a higher-order component that wraps your app component to enforce authentication.
import { Authenticator, useAuthenticator, View, Image, useTheme, ThemeProvider as LoginThemeProvider } from '@aws-amplify/ui-react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Imports the default styles for the Amplify UI components. This line ensures that the authenticator looks nice out of the box.
import '@aws-amplify/ui-react/styles.css';

// Imports the awsExports configuration file generated by the Amplify CLI. This file contains the AWS service configurations (like Cognito, AppSync, etc.) specific to your project.
import amplifyconfig from './amplifyconfiguration.json';

import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeContext } from './components/Contexts';
import Home from "./pages/Home/Home";
import Proposal from "./pages/Proposal/Proposal";
import Profile from "./pages/Profile/Profile";
import Logo1 from "./images/Logo1.png";

// Configures the Amplify library with the settings from aws-exports.js, which includes all the AWS service configurations for this project.
Amplify.configure({
  ...amplifyconfig,
  oauth: {
    domain: "pinky.auth.us-east-1.amazoncognito.com",
    scope: ["openid", "email"],
    redirectSignIn: "https://pinky.us.qinetiq.com/",
    redirectSignOut: "https://pinky.us.qinetiq.com/",
    responseType: "code",
  },
});

function App() {
  const { tokens } = useTheme();
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const [theme, setTheme] = useState(localStorage.getItem("PinkyTheme") ?? 'light');

  useEffect(() => {
    localStorage.setItem("PinkyTheme", theme);
  }, [theme]);

  const providerValues = {
    theme, setTheme
  };

  const darkTheme = createTheme({
    palette: {
      mode: theme,
    },
  });

  const loginTheme = {
    name: 'Pinky Theme',
    tokens: {
      components: {
        authenticator: {
          router: {
            boxShadow: `0 0 16px ${"#D9CAD3"}`,
            borderWidth: '0',
          },
          form: {
            padding: `${tokens.space.medium} ${tokens.space.xl} ${tokens.space.medium}`,
          },
        },
        button: {
          primary: {
            backgroundColor: "#906B7F",
            "&:hover": { backgroundColor: "#b86994" }
          },
          link: {
            color: "#906B7F",
          },
        },
        fieldcontrol: {
          _focus: {
            boxShadow: `0 0 0 2px ${"#906B7F"}`,
          },
        },
        tabs: {
          item: {
            color: "#D9CAD3",
            _active: {
              borderColor: "#906B7F",
              color: "#906B7F",
            },
          },
        },
      },
    },
  };

  const components = {
    Header() {
      return (
        <View textAlign="center" padding={1}>
          <Image
            width={250}
            alt="Pinky logo"
            src={Logo1}
          />
        </View>
      );
    },
    SignIn: {
      Footer() {
        const { toFederatedSignIn } = useAuthenticator();
        const provider = {
          custom: "Pinky-EntraID",
        };

        return (
          <View textAlign="center">
            <Button type="submit" variant="contained" onClick={() => toFederatedSignIn({ provider })}
              sx={{ mb: 2, width: "26rem", backgroundColor: "#906b7f", color: "white", "&:hover": { backgroundColor: "#b86994" } }}
              fontWeight="bold"
              size="large"
            >
              SSO (Single Sign-On)
            </Button>
          </View>
        );
      },
    },
  };

  return (
    <LoginThemeProvider theme={loginTheme}>
      {authStatus === 'authenticated' && user ?
        <div className="App">
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <ThemeContext.Provider value={providerValues}>
              <Routes>
                <Route path="/" element={<Home user={user} signOut={signOut} />} />
                <Route path="/Profile" element={<Profile user={user} signOut={signOut} />} />
                <Route path="/Proposal" element={<Proposal user={user} signOut={signOut} />} />
                <Route path="*" element={<Navigate replace to="/" />} />
              </Routes>
            </ThemeContext.Provider>
          </ThemeProvider>
        </div> : <Authenticator components={components} hideSignUp />
      }
    </LoginThemeProvider>
  );
}

export default App;
