import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Container,
    Button,
    Tooltip,
    Icon,
    ListItemText,
    Avatar
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { pink } from '@mui/material/colors';
import { Link } from "react-router-dom";
import MaterialUISwitch from './MaterialUISwitch';
import { ThemeContext } from './Contexts';
import { Logout, Person } from '@mui/icons-material';

function NavBar({ user, signOut, CustomToolBar }) {
    const themeContext = useContext(ThemeContext);
    const location = useLocation();
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const pages = ['Proposal'];
    const settings = ['Profile'];

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="sticky" sx={{ backgroundColor: "#906B7F", color: "#D9CAD3", height: 40 }}>
            <Container maxWidth="xl" sx={{ mt: -2 }}>
                <Toolbar disableGutters>
                    <Link to={`/`} style={{
                        mr: 2,
                        display: { xs: 'none', md: 'flex' },
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    }}>
                        <Typography
                            component="span"
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            PINKY
                        </Typography>
                    </Link>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Typography component="span" textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        PINKY
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Link key={page} to={`/${page}`} style={{ textDecoration: 'none' }}>
                                <Button
                                    onClick={handleCloseNavMenu}
                                    sx={{
                                        my: 2, color: 'white', display: 'block',
                                        backgroundColor: page === location.pathname.replace('/', '') ? "rgba(0, 0, 0, 0.3)" : "inherit",
                                        "&:hover": {
                                            backgroundColor: "rgba(0, 0, 0, 0.3)"
                                        }
                                    }}
                                >
                                    {page}
                                </Button>
                            </Link>
                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'flex' } }}>
                        {CustomToolBar ? CustomToolBar() : null}
                    </Box>
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'flex' } }}>
                        <MaterialUISwitch sx={{ m: 1 }} checked={themeContext.theme === "dark"} onChange={(e) => themeContext.setTheme(e.target.checked ? "dark" : "light")} />
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar sx={{ bgcolor: pink[500] }}>{`${user?.username.substring(14).substring(0, 1).toUpperCase()}`}</Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '25px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} sx={{ m: 1 }} onClick={handleCloseUserMenu}>
                                    <Icon color="inherit">
                                        <Person />
                                    </Icon>
                                    <ListItemText sx={{ mt: "6px", justifyContent: "center", display: "flex" }} primary={
                                        <Link to={`/${setting}`} style={{ color: 'inherit', textDecoration: 'none' }}>{setting}</Link>
                                    } />
                                </MenuItem>
                            ))}

                            <MenuItem key={'Logout'} sx={{ m: 1 }} onClick={signOut}>
                                <Icon color="inherit">
                                    <Logout />
                                </Icon>
                                <ListItemText sx={{ mt: "6px", justifyContent: "center", display: "flex" }} primary="Logout" />
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default NavBar;