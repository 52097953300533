import React from "react";
import {
    Box, Typography,
} from "@mui/material";
import ResultGroup from "./ResultGroup";

const Response = ({ results, handleRefreshNoLoading }) => {
    results.sort(function (a, b) { return new Date(b.BeginDateTime) - new Date(a.BeginDateTime); });
    const requirements = [...new Set(results.map((item) => item.RequirementText))];

    return (
        <Box sx={{ padding: 1, width: "100%" }}>
            <Typography component="span" sx={{ fontWeight: "bold" }}>Response</Typography>
            {requirements.map((RequirementText, index) => {
                const resultByReqId = results.filter(res => res.RequirementText === RequirementText);
                const displayResult = resultByReqId[0];
                return (
                    <ResultGroup
                        key={`ResultGroup-${RequirementText}-${index}`}
                        results={results}
                        RequirementText={RequirementText}
                        displayResult={displayResult}
                        handleRefreshNoLoading={handleRefreshNoLoading}
                    />
                )
            })}
        </Box>
    )
}

export default Response;