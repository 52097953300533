import { post } from 'aws-amplify/api';

export const fetchGenerateResult = async (documentId) => {
    try {
        const restOperation = post({
            apiName: 'PinkyGenerate',
            path: '/pinky/generate/result',
            options: {
                body: {
                    "DocumentId": documentId
                }
            }
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response.StatusCode === 200) {
            return response.Data;
        }
    } catch (e) {
        return false;
    }
    return false;
};

export const fetchGenerateDocument = async (documentId) => {
    try {
        const restOperation = post({
            apiName: 'PinkyGenerate',
            path: '/pinky/generate/document',
            options: {
                body: {
                    "DocumentId": documentId
                }
            }
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response.StatusCode === 200) {
            return response.Data;
        }
    } catch (e) {
        return false;
    }
    return false;
};