import React, { useState } from "react";
import {
    Box, Button, List, Typography,
} from "@mui/material";
import Document from "./Document";
import Section from "./Section";
import Requirement from "./Requirement";

const Request = ({
    document, sections, requirements,
    isEdit,
    handleCreate,
    addUpdateList,
    addDeleteList
}) => {

    const [newSections, setNewSections] = useState([]);
    const [newRequirements, setNewRequirements] = useState([]);

    const handleSectionChange = (type, documentId) => {
        const body = handleCreate(type, documentId)
        setNewSections([...newSections, body]);
    };

    const handleReqirementChange = (type, documentId, sectionId) => {
        const body = handleCreate(type, documentId, sectionId)
        setNewRequirements([...newRequirements, body]);
    };

    const handleUpdate = (type, field, id, value) => {
        addUpdateList({ Type: type, Field: field, Id: id, Value: value });
    };

    const handleDelete = (type, id) => {
        addDeleteList({ Type: type, Id: id });
    };

    return (
        <Box sx={{ padding: 1, width: "100%" }}>
            <Typography component="span" sx={{ fontWeight: "bold" }}>
                Request
            </Typography>

            <List
                key={`document-${document.Id}`}
                sx={{ bgcolor: 'background.paper', padding: 0, width: "98%" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <Document
                    document={document}
                    isEdit={isEdit}
                    handleUpdate={handleUpdate}
                    handleDelete={handleDelete}
                />
            </List>
            {sections.map((section, index) =>
                <List
                    key={`section-${section.Id}-${index}`}
                    sx={{ bgcolor: 'background.paper', padding: 0, width: "98%" }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    <Section
                        index={index}
                        section={section}
                        isEdit={isEdit}
                        handleUpdate={handleUpdate}
                        handleDelete={handleDelete}
                    />
                    {requirements.filter(req => req.SectionId === section.Id).map((requirement, index) =>
                        <List
                            key={`requirement-${requirement.Id}-${index}`}
                            component="div"
                            disablePadding
                        >
                            <Requirement
                                index={index}
                                requirement={requirement}
                                isEdit={isEdit}
                                handleUpdate={handleUpdate}
                                handleDelete={handleDelete}
                            />
                        </List>
                    )}
                    {newRequirements.filter(req => req.SectionId === section.Id).map((requirement, index) =>
                        <List
                            key={`requirement-${requirement.Id}-${index}`}
                            component="div"
                            disablePadding
                        >
                            <Requirement
                                index={index}
                                requirement={requirement}
                                isEdit={isEdit}
                                handleUpdate={handleUpdate}
                                handleDelete={handleDelete}
                            />
                        </List>
                    )}
                    {isEdit ?
                        <Box sx={{ width: 350 }}>
                            <Button variant="text" onClick={() => handleReqirementChange("Requirement", document.Id, section.Id)} >+ Add a New Requirement</Button>
                        </Box> : null
                    }
                </List>
            )}
            {newSections.map((section, index) =>
                <List
                    key={`section-${section.Id}-${index}`}
                    sx={{ bgcolor: 'background.paper', padding: 0, width: "98%" }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    <Section
                        index={index}
                        section={section}
                        isEdit={isEdit}
                        handleUpdate={handleUpdate}
                        handleDelete={handleDelete}
                    />
                    {requirements.filter(req => req.SectionId === section.Id).map((requirement, index) =>
                        <List
                            key={`requirement-${requirement.Id}-${index}`}
                            component="div"
                            disablePadding
                        >
                            <Requirement
                                index={index}
                                requirement={requirement}
                                isEdit={isEdit}
                                handleUpdate={handleUpdate}
                                handleDelete={handleDelete}
                            />
                        </List>
                    )}
                    {newRequirements.filter(req => req.SectionId === section.Id).map((requirement, index) =>
                        <List
                            key={`requirement-${requirement.Id}-${index}`}
                            component="div"
                            disablePadding
                        >
                            <Requirement
                                index={index}
                                requirement={requirement}
                                isEdit={isEdit}
                                handleUpdate={handleUpdate}
                                handleDelete={handleDelete}
                            />
                        </List>
                    )}
                    {isEdit ?
                        <Box sx={{ width: 350 }}>
                            <Button variant="text" onClick={() => handleReqirementChange("Requirement", document.Id, section.Id)} >+ Add a New Requirement</Button>
                        </Box> : null
                    }
                </List>
            )}
            {isEdit ?
                <Box sx={{ width: 200 }}>
                    <Button variant="text" onClick={() => handleSectionChange("Section", document.Id, null)} >+ Add a New Section</Button>
                </Box> : null
            }
        </Box>
    )
}

export default Request;