import { get } from 'aws-amplify/api';

export const fetchGetPromptSources = async (documentId) => {
    try {
        const restOperation = get({
            apiName: 'PinkyPromptSource',
            path: `/pinky/promptsource/${documentId}`
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response.StatusCode === 200) {
            return response.Data;
        }
    } catch (e) {
        return [];
    }
    return [];
};