import React, { useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';

const CopyToClipboard = (props) => {
    const { content } = props;
    const [isClicked, setIsClicked] = useState(false);
    const isLink = content.startsWith("https://");
    const isBlank = content ? false : true;

    const handleOnclick = () => {
        navigator.clipboard.writeText(content);
        setIsClicked(true);
        setTimeout(() => {
            setIsClicked(false);
        }, 3000); //run this after 3 seconds
    };

    return (
        !isBlank ?
            (isLink ?
                <Box sx={{ width: "100%", height: "auto", wordWrap: "break-word" }}>
                    <Link href={content} target="_blank" sx={{}}>{content} </Link>
                    <ContentCopy fontSize="tiny" className="copy" sx={{ "&:hover": { cursor: "pointer" } }} onClick={handleOnclick} />
                    <Typography component="div" sx={{ fontSize: "1rem" }}>{isClicked ? "Copied to Clipboard" : ""}</Typography>
                </Box> :
                <Box sx={{ width: "100%", height: "auto", wordWrap: "break-word" }}>
                    <Typography component="div" sx={{ display: "inline-block" }}>{content} </Typography>
                    <ContentCopy fontSize="tiny" className="copy" sx={{ "&:hover": { cursor: "pointer" } }} onClick={handleOnclick} />
                    <Typography component="div" sx={{ fontSize: "1rem" }}>{isClicked ? "Copied to Clipboard" : ""}</Typography>
                </Box>
            ) : null
    )
}

export default CopyToClipboard;