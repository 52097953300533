import React, { useState } from "react";
import {
    Box, List, ListItem, ListItemIcon, ListItemText, Collapse, Typography,
    Dialog, DialogTitle, DialogContent, DialogContentText, Grid
} from "@mui/material";
import { Check, ExpandLess, ExpandMore } from '@mui/icons-material';
import Result from "./Result";
import CopyToClipboard from "../../components/CopyToClipboard";

const ResultGroup = ({ results, RequirementText, displayResult, handleRefreshNoLoading }) => {
    const [open, setOpen] = useState(false);
    const [statusDialog, setStatusDialog] = useState(false);
    const filteredResults = results.filter(result => result.RequirementText === RequirementText);

    const handleClick = () => {
        if (!open === false) {
            handleRefreshNoLoading();
        }
        setOpen(!open);
    };

    const btn = {
        fontSize: 45,
        "&:hover": {
            cursor: "pointer"
        }
    };

    const icn = {
        paddingRight: 2
    };

    const statusTypeBackgroundColor = (status) => {
        if (status === "Success") {
            return "mediumseagreen";
        }
        else if (status === "In Progress") {
            return "orange";
        }
        else if (status === "Error") {
            return "crimson";
        }
        return "";
    };

    const StatusDialog = ({ result }) => {
        return (
            <>
                <Box
                    sx={{
                        textAlign: "center",
                        backgroundColor: statusTypeBackgroundColor(result.StatusType),
                        color: "white",
                        borderRadius: "25px",
                        "&:hover": {
                            cursor: result.StatusType === "Error" ? "pointer" : "inherit"
                        }
                    }}
                    onClick={() => result.StatusType === "Error" ? setStatusDialog(true) : null}
                >
                    {result.StatusType}
                </Box>
                <Dialog
                    open={statusDialog}
                    onClose={() => setStatusDialog(false)}
                >
                    <DialogTitle id="status-dialog-title">
                        <Box
                            sx={{
                                textAlign: "center",
                                backgroundColor: statusTypeBackgroundColor(result.StatusType),
                                color: "white",
                                borderRadius: "25px",
                            }}
                        >
                            {result.StatusType}
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="status-dialog-description">
                            {result.StatusMessage}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </>
        )
    };

    const TimeDisplay = ({ result }) => {
        return (
            <>

                <Typography component="span"
                    sx={{
                        padding: 1,
                        fontSize: "0.7rem",
                        borderRadius: "25px",
                    }}>
                    {new Date(result.BeginDateTime).toLocaleString()}
                </Typography>
                <Typography component="span"
                    sx={{
                        padding: 1,
                        fontSize: "0.7rem",
                        borderRadius: "25px",
                    }}>
                    {result.TimeElapsed}
                </Typography>
            </>
        )
    };

    const condensedText = (text) => {
        let finalText = "";
        let limit = 28;
        const words = text.split(" ");
        if (words.length > limit) {
            for (let word of words.slice(0, limit)) {
                finalText += word + " ";
            }
            finalText += "...";
        }
        else {
            finalText = text;
        }
        return finalText;
    };

    const hasSelectedResult = filteredResults.filter(item => item.Selected === true)?.length > 0;

    return (
        <>
            <ListItem >
                <ListItemIcon sx={icn}>
                    Requirement<br />Text
                </ListItemIcon>
                <ListItemText primary={
                    <Grid container >
                        <Grid item xs={12}>
                            <CopyToClipboard content={condensedText(displayResult.RequirementText)} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={4}>
                                    {hasSelectedResult ? <Check sx={{ color: "mediumseagreen" }} /> : ""}
                                </Grid>
                                <Grid item xs={3}>
                                    <StatusDialog result={displayResult} />
                                </Grid>
                                <Grid item xs={5}>
                                    <TimeDisplay result={displayResult} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                } />
                {open ?
                    <>
                        <ExpandLess sx={btn} onClick={handleClick} />
                        ({filteredResults.length})
                    </>
                    :
                    <>
                        <ExpandMore sx={btn} onClick={handleClick} />
                        ({filteredResults.length})
                    </>

                }
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ width: "97%" }}>
                    {filteredResults.map((result, index) => {
                        return (
                            <Result key={`ListItem-${result.RequirementText}-${index}`} result={result} handleRefreshNoLoading={handleRefreshNoLoading} />
                        )
                    })}
                </List>
            </Collapse>
        </>
    )
}

export default ResultGroup;