import { get, post, put, del } from 'aws-amplify/api';

export const fetchGetSections = async (documentId) => {
    try {
        const restOperation = get({
            apiName: 'PinkySection',
            path: `/pinky/section/${documentId}`
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response.StatusCode === 200) {
            return response.Data;
        }
    } catch (e) {
        return [];
    }
    return [];
};

export const fetchCreateSectionList = async (list) => {
    try {
        const restOperation = post({
            apiName: 'PinkySection',
            path: '/pinky/section',
            options: {
                body: {
                    List: list
                }
            }
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response.StatusCode === 200) {
            return true;
        }
    } catch (e) {
        return false;
    }
    return false;
};

export const fetchUpdateSectionList = async (list) => {
    try {
        const restOperation = put({
            apiName: 'PinkySection',
            path: '/pinky/section',
            options: {
                body: {
                    List: list
                }
            }
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response.StatusCode === 200) {
            return true;
        }
    } catch (e) {
        return false;
    }
    return false;
};

export const fetchDeleteSectionList = async (list) => {
    try {
        const restOperation = del({
            apiName: 'PinkySection',
            path: `/pinky/section/${list.join(",")}`
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response.StatusCode === 200) {
            return true;
        }
    } catch (e) {
        return false;
    }
    return false;
};