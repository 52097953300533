import { get, post, put, del } from 'aws-amplify/api';

export const fetchGetRequirements = async (documentId) => {
    try {
        const restOperation = get({
            apiName: 'PinkyRequirement',
            path: `/pinky/requirement/${documentId}`
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response.StatusCode === 200) {
            return response.Data;
        }
    } catch (e) {
        return [];
    }
    return [];
};

export const fetchCreateRequirementList = async (list) => {
    try {
        const restOperation = post({
            apiName: 'PinkyRequirement',
            path: '/pinky/requirement',
            options: {
                body: {
                    List: list
                }
            }
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response.StatusCode === 200) {
            return true;
        }
    } catch (e) {
        return false;
    }
    return false;
};

export const fetchUpdateRequirementList = async (list) => {
    try {
        const restOperation = put({
            apiName: 'PinkyRequirement',
            path: '/pinky/requirement',
            options: {
                body: {
                    List: list
                }
            }
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response.StatusCode === 200) {
            return true;
        }
    } catch (e) {
        return false;
    }
    return false;
};

export const fetchDeleteRequirementList = async (list) => {
    try {
        const restOperation = del({
            apiName: 'PinkyRequirement',
            path: `/pinky/requirement/${list.join(",")}`
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response.StatusCode === 200) {
            return true;
        }
    } catch (e) {
        return false;
    }
    return false;
};