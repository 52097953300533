import React from 'react';
import { ButtonGroup, Box, Tooltip, Divider, IconButton } from '@mui/material';
import { Add, Check, Refresh, Save, Cancel, Edit, Download } from '@mui/icons-material';

const ToolBar = ({ handleCreateDocument, handleSubmit, handleDownload, handleRefresh, handleSaveChange, handleCancelChange, isEdit, setIsEdit }) => {
    const getStyles = () => {
        return {
            color: "white",
            width: "2rem",
            height: "1.8rem",
            m: 1,
        };
    };

    return (
        <Box sx={{ position: "relative" }}>
            <ButtonGroup sx={{ float: "center" }}>
                {isEdit ?
                    <>
                        <Tooltip title={<div style={{ fontSize: "1rem" }}>Save</div>}>
                            <IconButton sx={getStyles()} onClick={handleSaveChange}>
                                <Save />
                            </IconButton >
                        </Tooltip>
                        <Divider orientation="vertical" variant="middle" flexItem /><Divider orientation="vertical" variant="middle" flexItem />
                        <Tooltip title={<div style={{ fontSize: "1rem" }}>Cancel</div>}>
                            <IconButton sx={getStyles()} onClick={handleCancelChange}>
                                <Cancel />
                            </IconButton >
                        </Tooltip>
                    </>
                    :
                    <>
                        <Tooltip title={<div style={{ fontSize: "1rem" }}>Create a New Document</div>}>
                            <IconButton sx={getStyles()} onClick={handleCreateDocument}>
                                <Add />
                            </IconButton >
                        </Tooltip>
                        <Divider orientation="vertical" variant="middle" flexItem /><Divider orientation="vertical" variant="middle" flexItem />
                        <Tooltip title={<div style={{ fontSize: "1rem" }}>Edit</div>}>
                            <IconButton sx={getStyles()} onClick={() => setIsEdit(true)}>
                                <Edit />
                            </IconButton >
                        </Tooltip>
                        <Divider orientation="vertical" variant="middle" flexItem /><Divider orientation="vertical" variant="middle" flexItem />
                        <Tooltip title={<div style={{ fontSize: "1rem" }}>Submit</div>}>
                            <IconButton sx={getStyles()} onClick={handleSubmit}>
                                <Check />
                            </IconButton >
                        </Tooltip>
                        <Divider orientation="vertical" variant="middle" flexItem /><Divider orientation="vertical" variant="middle" flexItem />
                        <Tooltip title={<div style={{ fontSize: "1rem" }}>Download</div>}>
                            <IconButton sx={getStyles()} onClick={handleDownload}>
                                <Download />
                            </IconButton >
                        </Tooltip>
                        <Divider orientation="vertical" variant="middle" flexItem /><Divider orientation="vertical" variant="middle" flexItem />
                        <Tooltip title={<div style={{ fontSize: "1rem" }}>Refresh</div>}>
                            <IconButton sx={getStyles()} onClick={handleRefresh}>
                                <Refresh />
                            </IconButton >
                        </Tooltip>
                    </>
                }
            </ButtonGroup>
        </Box>
    )
}

export default ToolBar;