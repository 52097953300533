import React, { useState } from "react";
import {
    Grid, Box, List, ListItem, ListItemIcon, ListItemText, Typography,
    Dialog, DialogTitle, DialogContent, DialogContentText, Checkbox
} from "@mui/material";
import { Check, Close } from '@mui/icons-material';
import CopyToClipboard from "../../components/CopyToClipboard";
import useQuickAlert from "../../components/QuickAlert";
import { fetchDeleteResultList, fetchUpdateResultList } from "../../services/Result";
import DeleteDialog from "../../components/DeleteDialog";

const Result = ({ result, handleRefreshNoLoading }) => {
    const addAlert = useQuickAlert();
    const [statusDialog, setStatusDialog] = useState(false);
    const [detailDialog, setDetailDialog] = useState(false);
    const [selected, setSelected] = useState(result?.Selected ? true : false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const icn = {
        paddingRight: 2
    };

    const statusTypeBackgroundColor = (status) => {
        if (status === "Success") {
            return "mediumseagreen";
        }
        else if (status === "In Progress") {
            return "orange";
        }
        else if (status === "Error") {
            return "crimson";
        }
        return "";
    };

    const StatusDialog = ({ result }) => {
        return (
            <>
                <Box
                    sx={{
                        textAlign: "center",
                        backgroundColor: statusTypeBackgroundColor(result.StatusType),
                        color: "white",
                        borderRadius: "25px",
                        "&:hover": {
                            cursor: result.StatusType === "Error" ? "pointer" : "inherit"
                        }
                    }}
                    onClick={() => result.StatusType === "Error" ? setStatusDialog(true) : null}
                >
                    {result.StatusType}
                </Box>
                <Dialog
                    open={statusDialog}
                    onClose={() => setStatusDialog(false)}
                >
                    <DialogTitle id="status-dialog-title">
                        <Box
                            sx={{
                                textAlign: "center",
                                backgroundColor: statusTypeBackgroundColor(result.StatusType),
                                color: "white",
                                borderRadius: "25px",
                            }}
                        >
                            {result.StatusType}
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="status-dialog-description">
                            {result.StatusMessage}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </>
        )
    };

    const DetailDialog = ({ result }) => {
        return (
            <>
                <Typography component="span"
                    sx={{
                        float: "right",
                        padding: 1,
                        fontSize: "0.7rem",
                        color: "#1976d2",
                        borderRadius: "25px",
                        "&:hover": {
                            cursor: "pointer"
                        }
                    }}
                    onClick={() => setDetailDialog(true)}>
                    {"Result Details"}
                </Typography>
                <Dialog
                    open={detailDialog}
                    onClose={() => setDetailDialog(false)}
                >
                    <DialogTitle id="status-dialog-title" sx={{ margin: "auto" }}>
                        <Typography component="span" sx={{ fontWeight: "bold" }}>Result Details</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="status-dialog-description-1"
                            sx={{ backgroundColor: localStorage.getItem("PinkyTheme") === "light" ? "#eaf6f6" : "#586F7C" }}
                        >
                            <Typography component="span" sx={{ fontWeight: "bold" }}>Request</Typography>
                            <List component="div" disablePadding>
                                <ListItem>
                                    <ListItemIcon sx={icn}>
                                        Document<br />Name
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <CopyToClipboard content={`${result.DocumentName}`} />
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={icn}>
                                        Document<br />Customer
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <CopyToClipboard content={`${result.DocumentCustomer}`} />
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={icn}>
                                        Document<br />Summary
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <CopyToClipboard content={`${result.DocumentSummary}`} />
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={icn}>
                                        Document<br />Preferred<br />Name
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <CopyToClipboard content={`${result.DocumentPreferredName ?? "Team QinetiQ"}`} />
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={icn}>
                                        Section<br />Name
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <CopyToClipboard content={`${result.SectionName}`} />
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={icn}>
                                        Section<br />Customer
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <CopyToClipboard content={`${result.SectionCustomer}`} />
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={icn}>
                                        Section<br />Summary
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <CopyToClipboard content={`${result.SectionText}`} />
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={icn}>
                                        Requirement<br />Text
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <CopyToClipboard content={`${result.RequirementText}`} />
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={icn}>
                                        Requirement<br />UseGeneratedPrompt
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            result.RequirementUseGeneratedPrompt ? <Check /> : <Close />
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={icn}>
                                        Requirement<br />Prompt
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <CopyToClipboard content={`${result.RequirementPrompt}`} />
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={icn}>
                                        Requirement<br />Minimum<br />Words
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            result.RequirementMinWords ?? "100"
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={icn}>
                                        Requirement<br />Maximum<br />Words
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            result.RequirementMaxWords ?? "300"
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={icn}>
                                        Requirement<br />Additional<br />Instructions
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <CopyToClipboard content={`${result.RequirementAdditionalInstructions}`} />
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={icn}>
                                        Generated<br />Prompt
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <CopyToClipboard content={`${result.GeneratedPrompt}`} />
                                        }
                                    />
                                </ListItem>
                            </List>
                        </DialogContentText>
                        <DialogContentText id="status-dialog-description-2"
                            sx={{ backgroundColor: localStorage.getItem("PinkyTheme") === "light" ? "#e0ffcd" : "#1C7C54" }}
                        >
                            <Typography component="span" sx={{ fontWeight: "bold" }}>Response</Typography>
                            <List component="div" disablePadding>
                                <ListItem>
                                    <ListItemIcon sx={icn}>
                                        Generated<br />Text
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <CopyToClipboard content={`${result.GeneratedText}`} />
                                        }
                                    />
                                </ListItem>
                            </List>
                        </DialogContentText>
                        {result?.Sources?.length > 0 ?
                            <DialogContentText id="status-dialog-description-3"
                                sx={{ backgroundColor: localStorage.getItem("PinkyTheme") === "light" ? "#fdffcd" : "#70694D" }}
                            >
                                <Typography component="span" sx={{ fontWeight: "bold" }}>Prompt Sources</Typography>
                                {result?.Sources?.map((source, index) =>
                                    <List key={`source-${source.Id}`} component="div" disablePadding>
                                        <ListItemIcon sx={icn}>
                                            {`${index + 1}.`}
                                        </ListItemIcon>
                                        <ListItem>
                                            <ListItemIcon sx={icn}>
                                                Business<br />Unit
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={source.BusinessUnit}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon sx={icn}>
                                                Customer
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={source.Customer}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon sx={icn}>
                                                Source<br />Path
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={source.SourcePath}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon sx={icn}>
                                                Page(s)
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={source.Pages}
                                            />
                                        </ListItem>
                                    </List>
                                )}
                            </DialogContentText>
                            : null
                        }
                    </DialogContent>
                </Dialog>
            </>
        )
    };

    const TimeDisplay = ({ result }) => {
        return (
            <>

                <Typography component="span"
                    sx={{
                        padding: 1,
                        fontSize: "0.7rem",
                        borderRadius: "25px",
                    }}>
                    {new Date(result.BeginDateTime).toLocaleString()}
                </Typography>
                <Typography component="span"
                    sx={{
                        padding: 1,
                        fontSize: "0.7rem",
                        borderRadius: "25px",
                    }}>
                    {result.TimeElapsed}
                </Typography>
            </>
        )
    };

    const handleSelectedChange = (e) => {
        setSelected(e.target.checked);
        fetchUpdateResultList([{
            ...result,
            Selected: e.target.checked
        }]);
    };

    const handleOnDelete = async () => {
        setOpenDeleteDialog(false);
        const res = await fetchDeleteResultList([result.Id]);
        if (res) {
            addAlert({
                message: "Successfully deleted a Result",
                severity: "success",
            });
            handleRefreshNoLoading();
        }
        else {
            addAlert({
                message: "Failed to deleted a Result",
                severity: "error",
            });
        }
    };

    return (
        <ListItem sx={{ border: "1px solid gray", borderRadius: "25px", borderTop: "none" }}>
            <ListItemIcon sx={icn}>
                <Checkbox name={result.Id} checked={selected} onChange={handleSelectedChange} />
                Generated<br />Text
            </ListItemIcon>
            <ListItemText primary={
                <Grid container >
                    <Grid item xs={12}>
                        <Grid container >
                            <Grid item xs={10}>
                                <CopyToClipboard content={result.GeneratedText} />
                            </Grid>
                            <Grid item xs={2}>
                                <DeleteDialog
                                    open={openDeleteDialog}
                                    setOpen={setOpenDeleteDialog}
                                    title={`Generated Text: `}
                                    text={`${result.GeneratedText}`}
                                    handleSubmit={handleOnDelete}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={4}>
                                <DetailDialog result={result} />
                            </Grid>
                            <Grid item xs={3}>
                                <StatusDialog result={result} />
                            </Grid>
                            <Grid item xs={5}>
                                <TimeDisplay result={result} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            } />
        </ListItem>
    )
}

export default Result;