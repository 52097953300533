import React, { useState, useEffect } from "react";
import {
    List, ListItem, ListItemIcon, ListItemText, Collapse
} from "@mui/material";
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import InlineEditText from "../../components/InlineEditText";

const Document = ({ document, isEdit, handleUpdate, handleDelete }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (isEdit) {
            setOpen(true);
        }
    }, [isEdit]);

    const handleClick = () => {
        setOpen(!open);
    };

    const btn = {
        fontSize: 45,
        marginTop: 1,
        "&:hover": {
            cursor: "pointer"
        }
    }

    const icn = {
        paddingRight: 2
    }

    return (
        <>
            <ListItem >
                <ListItemIcon sx={icn}>
                    Document<br />Name
                </ListItemIcon>
                <ListItemText
                    primary={
                        <InlineEditText
                            type="Document"
                            field="Name"
                            id={document.Id}
                            text={document.Name}
                            isEdit={isEdit}
                            handleUpdate={handleUpdate}
                            handleDelete={handleDelete}
                            required={true}
                        />
                    }
                />
                {open ? <ExpandLess sx={btn} onClick={handleClick} /> : <ExpandMore sx={btn} onClick={handleClick} />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ width: "99%", border: "1px solid gray", borderRadius: "25px", borderTop: "none" }}>
                    <ListItem>
                        <ListItemIcon sx={icn}>
                            Document<br />Customer
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <InlineEditText
                                    type="Document"
                                    field="Customer"
                                    id={document.Id}
                                    text={document.Customer}
                                    isEdit={isEdit}
                                    handleUpdate={handleUpdate}
                                    required={true}
                                />
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon sx={icn}>
                            Document<br />Summary
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <InlineEditText
                                    type="Document"
                                    field="Summary"
                                    id={document.Id}
                                    text={document.Summary}
                                    isEdit={isEdit}
                                    handleUpdate={handleUpdate}
                                    required={true}
                                />
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon sx={icn}>
                            Document<br />Preferred<br />Name
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <InlineEditText
                                    type="Document"
                                    field="PreferredName"
                                    id={document.Id}
                                    text={document.PreferredName ?? "Team QinetiQ"}
                                    isEdit={isEdit}
                                    handleUpdate={handleUpdate}
                                />
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon sx={icn}>
                            Document<br />Desired<br />Template
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <InlineEditText
                                    type="Document"
                                    field="DesiredTemplate"
                                    id={document.Id}
                                    text={document.DesiredTemplate ?? "QinetiQ"}
                                    isEdit={isEdit}
                                    handleUpdate={handleUpdate}
                                    selectOptions={["QinetiQ", "Avantus"]}
                                />
                            }
                        />
                    </ListItem>
                </List>
            </Collapse>
        </>
    )
}

export default Document;