import React, { useState, useEffect } from "react";
import {
    Box, List, ListItem, ListItemIcon, ListItemText, Collapse, Checkbox, FormControlLabel, Button
} from "@mui/material";
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import InlineEditText from "../../components/InlineEditText";

const Requirement = ({ index, requirement, isEdit, handleUpdate, handleDelete }) => {
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(requirement.UseGeneratedPrompt);
    const [advancedOptions, setAdvancedOptions] = useState(false);

    const handleChecked = (type, field, id, checked) => {
        setChecked(checked)
        handleUpdate(type, field, id, checked);
    }

    useEffect(() => {
        if (isEdit) {
            setOpen(true);
        }
    }, [isEdit]);

    const handleClick = () => {
        setOpen(!open);
        setAdvancedOptions(false);
    };

    const btn = {
        fontSize: 45,
        marginTop: 1,
        "&:hover": {
            cursor: "pointer"
        }
    }

    const icn = {
        paddingRight: 2
    }

    return (
        <>
            <ListItem >
                <ListItemIcon sx={icn}>
                </ListItemIcon>
                <ListItemIcon sx={icn}>
                    ({index + 1})
                </ListItemIcon>
                <ListItemIcon sx={icn}>
                    Requirement<br />Text
                </ListItemIcon>
                <ListItemText
                    primary={
                        <InlineEditText
                            type="Requirement"
                            field="Text"
                            id={requirement.Id}
                            text={requirement.Text}
                            isEdit={isEdit}
                            handleUpdate={handleUpdate}
                            handleDelete={handleDelete}
                            required={true}
                        />
                    }
                />
                {open ? <ExpandLess sx={btn} onClick={handleClick} /> : <ExpandMore sx={btn} onClick={handleClick} />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {advancedOptions ?
                    <List component="div" disablePadding sx={{ width: "99%", border: "1px solid gray", borderRadius: "25px", borderTop: "none" }}>
                        <ListItem>
                            <Box sx={{ display: 'block', flexDirection: 'column', ml: 6 }}>
                                <FormControlLabel
                                    disabled={!isEdit}
                                    label={"Use Generated Prompt"}
                                    control={<Checkbox checked={checked} onChange={(e) =>
                                        handleChecked("Requirement", "UseGeneratedPrompt", requirement.Id, e.target.checked)
                                    } />}
                                />
                            </Box>
                        </ListItem>
                        {checked ?
                            <>
                                <ListItem>
                                    <ListItemIcon sx={icn}>
                                        Minimum Words
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <InlineEditText
                                                type="Requirement"
                                                field="MinWords"
                                                id={requirement.Id}
                                                text={requirement.MinWords ?? "100"}
                                                isEdit={isEdit}
                                                handleUpdate={handleUpdate}
                                            />
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={icn}>
                                        Maximum Words
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <InlineEditText
                                                type="Requirement"
                                                field="MaxWords"
                                                id={requirement.Id}
                                                text={requirement.MaxWords ?? "300"}
                                                isEdit={isEdit}
                                                handleUpdate={handleUpdate}
                                            />
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={icn}>
                                        Additional Instructions
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <InlineEditText
                                                type="Requirement"
                                                field="AdditionalInstructions"
                                                id={requirement.Id}
                                                text={requirement.AdditionalInstructions}
                                                isEdit={isEdit}
                                                handleUpdate={handleUpdate}
                                            />
                                        }
                                    />
                                </ListItem>
                            </>
                            :
                            <ListItem>
                                <ListItemIcon sx={icn}>
                                    Prompt
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <InlineEditText
                                            type="Requirement"
                                            field="Prompt"
                                            id={requirement.Id}
                                            text={requirement.Prompt}
                                            isEdit={isEdit}
                                            handleUpdate={handleUpdate}
                                        />
                                    }
                                />
                            </ListItem>
                        }
                        <ListItem>
                            <ListItemIcon sx={icn}>
                                LLM Persona Description
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <InlineEditText
                                        type="Requirement"
                                        field="LLMPersonaDescription"
                                        id={requirement.Id}
                                        text={requirement.LLMPersonaDescription ?? "You are a solution architect responding to a query from a government customer. Your answers should avoid conversational fluff, be precise, and cite relevant examples from the provided context where appropriate."}
                                        isEdit={isEdit}
                                        handleUpdate={handleUpdate}
                                    />
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <Box sx={{ width: 350, textAlign: "center", margin: "auto" }}>
                                <Button variant="text" onClick={() => setAdvancedOptions(false)}>Hide Advanced Options</Button>
                            </Box>
                        </ListItem>
                    </List>
                    :
                    <List component="div" disablePadding sx={{ width: "99%", border: "1px solid gray", borderRadius: "25px", borderTop: "none" }}>
                        <ListItem>
                            <Box sx={{ width: 350, textAlign: "center", margin: "auto" }}>
                                <Button variant="text" onClick={() => setAdvancedOptions(true)}>Show Advanced Options</Button>
                            </Box>
                        </ListItem>
                    </List>
                }
            </Collapse>
        </>
    )
}

export default Requirement;