import React, { useState, useEffect } from "react";
import {
    List, ListItem, ListItemIcon, ListItemText, Collapse
} from "@mui/material";
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import InlineEditText from "../../components/InlineEditText";

const Section = ({ index, section, isEdit, handleUpdate, handleDelete }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (isEdit) {
            setOpen(true);
        }
    }, [isEdit]);

    const handleClick = () => {
        setOpen(!open);
    };

    const btn = {
        fontSize: 45,
        marginTop: 1,
        "&:hover": {
            cursor: "pointer"
        }
    }

    const icn = {
        paddingRight: 2
    }

    return (
        <>
            <ListItem >
                <ListItemIcon sx={icn}>
                    ({index + 1})
                </ListItemIcon>
                <ListItemIcon sx={icn}>
                    Section<br />Name
                </ListItemIcon>
                <ListItemText
                    primary={
                        <InlineEditText
                            type="Section"
                            field="Name"
                            id={section.Id}
                            text={section.Name}
                            isEdit={isEdit}
                            handleUpdate={handleUpdate}
                            handleDelete={handleDelete}
                        />
                    }
                />
                {open ? <ExpandLess sx={btn} onClick={handleClick} /> : <ExpandMore sx={btn} onClick={handleClick} />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ width: "99%", border: "1px solid gray", borderRadius: "25px", borderTop: "none" }}>
                    <ListItem>
                        <ListItemIcon sx={icn}>
                            Section<br />Customer
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <InlineEditText
                                    type="Section"
                                    field="Customer"
                                    id={section.Id}
                                    text={section.Customer}
                                    isEdit={isEdit}
                                    handleUpdate={handleUpdate}
                                />
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon sx={icn}>
                            Section<br />Summary
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <InlineEditText
                                    type="Section"
                                    field="Text"
                                    id={section.Id}
                                    text={section.Text}
                                    isEdit={isEdit}
                                    handleUpdate={handleUpdate}
                                />
                            }
                        />
                    </ListItem>
                </List>
            </Collapse>
        </>
    )
}

export default Section;