import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import NavBar from "../../components/NavBar";

const Home = ({ user, signOut }) => {
    return (
        <>
            <NavBar user={user} signOut={signOut} />
            <Box sx={{ padding: 2 }}>
                <Typography sx={{ fontFamily: "cursive", display: "block", mt: 20, fontSize: 30 }}>
                    <span style={{ color: "#906B7F" }}>P</span><span>roposal </span>
                    <span style={{ color: "#906B7F" }}>I</span><span>ntelligence </span>
                    <span style={{ color: "#906B7F" }}>N</span><span>eural </span>
                    <span style={{ color: "#906B7F" }}>K</span><span>nowledge </span>
                    <span style={{ color: "#906B7F" }}>Y</span><span>ielder</span>
                </Typography>
                <Link to="/Proposal" style={{ textDecoration: 'none' }}>
                    <Button variant="outlined" sx={{ fontSize: "2rem", mt: 10 }}>
                        Get Started
                    </Button>
                </Link>
            </Box>
        </>
    )
}

export default Home;