import React, { useContext } from "react";
import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { createContext, useState } from "react";

const ALERT_SETTINGS = Object.freeze({
    duration: 5000,
    severity: {
        SUCCESS: "success",
        INFO: "info",
        WARNING: "warning",
        ERROR: "error",
    },
    anchorOrigin: {
        topCenter: {
            vertical: "top",
            horizontal: "center",
        },
    },
});

export function QuickAlertContextProvider({ children }) {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState(ALERT_SETTINGS.severity.SUCCESS);
    const duration = ALERT_SETTINGS.duration;
    const anchorOrigin = ALERT_SETTINGS.anchorOrigin.topCenter;

    const handleClose = () => {
        setOpen(false);
    };

    const addAlert = (props) => {
        const { message, severity } = props;
        let sev = ALERT_SETTINGS.severity.SUCCESS;
        switch (severity) {
            case "info":
                sev = ALERT_SETTINGS.severity.INFO;
                break;
            case "warning":
                sev = ALERT_SETTINGS.severity.WARNING;
                break;
            case "error":
                sev = ALERT_SETTINGS.severity.ERROR;
                break;
            default:
                sev = ALERT_SETTINGS.severity.SUCCESS;
                break;
        }
        setSeverity(sev);
        setMessage(message);
        setOpen(true);
    };

    return (
        <QuickAlertContext.Provider value={addAlert}>
            {children}
            <Snackbar
                open={open}
                autoHideDuration={duration}
                onClose={handleClose}
                anchorOrigin={anchorOrigin}
            >
                <Alert
                    onClose={handleClose}
                    severity={severity}
                >
                    {message}
                </Alert>
            </Snackbar>
        </QuickAlertContext.Provider>
    );
}

const QuickAlertContext = createContext();

export default function useQuickAlert() {
    return useContext(QuickAlertContext);
}